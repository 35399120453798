<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" class="container" ngNativeValidate>
    <div class="row justify-content-md-center mt-5">
        <div class="col-5">
            <p class="lead text-center mb-4">{{'deleteAccount' | i18n}}</p>
            <div class="card">
                <div class="card-body">
                    <app-callout type="warning">{{'deleteAccountWarning' | i18n}}</app-callout>
                    <p class="text-center">
                        <strong>{{email}}</strong>
                    </p>
                    <p>{{'deleteRecoverConfirmDesc' | i18n}}</p>
                    <hr>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-danger btn-block btn-submit" [disabled]="form.loading">
                            <span>{{'deleteAccount' | i18n}}</span>
                            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                        </button>
                        <a routerLink="/" class="btn btn-outline-secondary btn-block ml-2 mt-0">
                            {{'cancel' | i18n}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
